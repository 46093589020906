import React, { Fragment } from 'react';
import Logo from './images/logo-transparent-white.svg';
import MainMenu from './components/MainMenu';
import './App.css';

function App() {
  const content = {
    display: 'flex',
    // alignItems: 'center',
    flexDirection: 'column',
    // justifyContent: 'center',
    paddingTop: 80,
    paddingBottom: 80
  };
  const item = {
    textAlign: 'center',
  };
  return (
    <Fragment>
      <div style={content}>
        <div style={item}>
          <img src={Logo} alt="logo" />
        </div>
      </div>
      <div>
        <MainMenu />
      </div>
      <div style={{ textAlign: 'center', color: '#ADB0D2', paddingTop: 80 }}>
        <h6>Copyright © Tecnica Business Systems, all rights reserved.</h6>
      </div>
    </Fragment>
  );
}

export default App;
