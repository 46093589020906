import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Equalizer from '@material-ui/icons/Equalizer';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import InfoIcon from '@material-ui/icons/Info';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';


const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: '30px',
    paddingRight: '30px',
    paddingBottom: '16px'
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    flexBasis: '33.33%',
    flexShrink: 0,
    color: '#ADB0D2',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(18),
    color: '#ADB0D2',
  },
  ExpansionPanel: {
    backgroundColor: '#282C49',
    color: '#ADB0D2',
  },
  icon: {
    width: 40,
    marginRight: 10,
  },
}));

const MainMenu = (props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handlePanel1Click = () => {
    window.location.replace('https://tbsreport.tecnicasystems.com/ui');
  }
  const handlePanel2Click = () => {
    window.location.replace('https://helpdesk.tecnicasystems.com/');
  }
  const handlePanel3Click = () => {
    window.location.replace('https://tecnicasystems.com/');
  }

  return (
    <Fragment>
      <div className={classes.root}>
        <ExpansionPanel className={classes.ExpansionPanel} expanded={expanded === 'panel5'} onClick={handlePanel1Click} onChange={handleChange('panel5')}>
          <ExpansionPanelSummary
            expandIcon={<ChevronRightIcon style={{ color: '#ADB0D2' }} />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <div className={classes.icon}><Equalizer /></div>
            <Typography className={classes.secondaryHeading}>Management Reports</Typography>
          </ExpansionPanelSummary>
        </ExpansionPanel>
      </div>

      <div className={classes.root}>
        <ExpansionPanel className={classes.ExpansionPanel} expanded={expanded === 'panel5'} onClick={handlePanel2Click} onChange={handleChange('panel5')}>
          <ExpansionPanelSummary
            expandIcon={<ChevronRightIcon style={{ color: '#ADB0D2' }} />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <div className={classes.icon}><RoomServiceIcon /></div>
            <Typography className={classes.secondaryHeading}>Support Services</Typography>
          </ExpansionPanelSummary>
        </ExpansionPanel>
      </div>

      <div className={classes.root}>
        <ExpansionPanel className={classes.ExpansionPanel} expanded={expanded === 'panel5'} onClick={handlePanel3Click} onChange={handleChange('panel5')}>
          <ExpansionPanelSummary
            expandIcon={<ChevronRightIcon style={{ color: '#ADB0D2' }} />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <div className={classes.icon}><InfoIcon /></div>
            <Typography className={classes.secondaryHeading}>About Us</Typography>
          </ExpansionPanelSummary>
        </ExpansionPanel>
      </div>

    </Fragment>
  )
}

export default MainMenu;